import { alphabetOrdering } from "./../../../helpers/strings"
import { mutationTypes } from "./constants"

export default {
  [mutationTypes.SET_COLORS](state, colors) {
    const myColor = colors.map((color) => ({
      ...color,
      icon: { url: color.image ? `${color.image}/small` : null },
      type: color.color_type,
      items: color.children.map((item) => ({
        ...item,
        icon: { url: item.image ? `${item.image}/small` : null }
      }))
    }))

    state.groups = alphabetOrdering(myColor)
  },

  [mutationTypes.CHANGE_ICON](state, { groupId, id, icon }) {
    const group = state.groups.find((group) => group.id === groupId)

    if (!id) {
      group.icon.url = icon ? `${icon}/small` : null
    } else {
      const item = group.items.find((item) => item.id === id)

      item.icon.url = icon ? `${icon}/small` : null
    }
  },

  [mutationTypes.CHANGE_VALIDATION](state, { groupId, itemId }) {
    const groupItem = state.groups.find((item) => item.id === groupId)

    // Якщо зміна групи
    if (!itemId) {
      groupItem.validate = 1
      groupItem.items = groupItem.items.map((item) => ({
        ...item,
        validate: 1
      }))
    } else {
      // Якщо зміна item в групі
      const groupItemChild = groupItem.items.find((item) => item.id === itemId)

      groupItemChild.validate = 1
    }
  },

  [mutationTypes.CHANGE_TYPE](state, { id, color_type }) {
    const groupItem = state.groups.find((item) => item.id === id)

    groupItem.type = color_type
  }
}
