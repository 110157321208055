<template>
  <div
    class="order-status"
    v-if="getStatus[status]"
    :style="getStatus[status].style"
  >
    <img :src="getStatus[status].icon" :alt="getStatus[status].title" />
    <span>{{ getStatus[status].title }}</span>
  </div>
</template>

<script>
import { ORDER_STATUSES } from "../constants"
// icons
import iconBag from "@/pages/Orders/assets/icons/bag.svg"
import iconDelivery from "@/pages/Orders/assets/icons/delivery.svg"
import iconFalse from "@/pages/Orders/assets/icons/false.svg"
import iconTrue from "@/pages/Orders/assets/icons/true.svg"
import iconReset from "@/pages/Orders/assets/icons/reset.svg"
import iconResetWhite from "@/pages/Orders/assets/icons/reset-white.svg"
import iconExclamationMark from "@/pages/Orders/assets/icons/exclamation-mark.svg"
import iconClock from "@/pages/Orders/assets/icons/clock.svg"
import iconBagFull from "@/pages/Orders/assets/icons/bag-full.svg"
import iconQueue from "@/pages/Orders/assets/icons/queue.svg"

export default {
  name: "orderStatus",
  props: {
    status: {
      type: String,
      default: ORDER_STATUSES["PROCESSING"],
      required: true,
      validator(value) {
        return Object.keys(ORDER_STATUSES).includes(value)
      }
    }
  },

  computed: {
    getStatus() {
      return {
        PROCESSING: {
          title: "обробляється",
          icon: iconReset,
          style: { background: "#C6E6D6" }
        },

        PROCESSING_PROBLEMATIC: {
          title: "обробляється",
          icon: iconResetWhite,
          style: { background: "#FC536A", color: "#fff" }
        },

        GOING_TO: {
          title: "збирається",
          icon: iconBag,
          style: { background: "#D7E2F3" }
        },

        COLLECTED: {
          title: "зібрано",
          icon: iconBagFull,
          style: { background: "#D7E2F3" }
        },

        TRANSFERRED_NP: {
          title: "передано НП",
          icon: iconDelivery,
          style: { background: "#D7E2F3" }
        },

        WAITING_AT_THE_POINT: {
          title: "очікує в точці видачі",
          icon: iconTrue,
          style: { background: "#C6E6D6" }
        },

        RECEIVED: {
          title: "отримано",
          icon: iconTrue,
          style: { background: "#C6E6D6" }
        },

        CANCELLED: {
          title: "скасовано",
          icon: iconFalse,
          style: {
            background: "linear-gradient(90deg, #F69090 0%, #F6B5BF 100%)"
          }
        },

        RETURN: {
          title: "повернення",
          icon: iconFalse,
          style: { background: "#FFD5A6" }
        },

        PROBLEMATIC: {
          title: "проблемне",
          icon: iconExclamationMark,
          style: { background: "#FC536A", color: "#fff" }
        },

        IN_QUEUE: {
          title: "в черзі",
          icon: iconQueue,
          style: { background: "#B9E1F4" }
        },

        WAITING_DOWNLOAD: {
          title: "очікує відвантаження",
          icon: iconClock,
          style: { background: "#DFD8F9" }
        },

        REFUSAL: {
          title: "відмова",
          icon: iconFalse,
          style: {
            background: "linear-gradient(90deg, #F69090 0%, #F6B5BF 100%)"
          }
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.order-status {
  border-radius: 26.5px;
  display: inline-flex;
  padding: 6px 16px;
  align-items: center;
  gap: 4px;
  font-size: 10px;
  line-height: 10px;
  color: #000;
  path {
    fill: #fff;
  }
}
</style>
