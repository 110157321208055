export const setCookie = (cname, cvalue, exdays = 365) => {
  const d = new Date()

  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000)
  let expires = "expires=" + d.toUTCString()

  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/"
}

export const getCookie = (cname) => {
  let name = cname + "="
  let ca = document.cookie.split(";")

  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]

    while (c.charAt(0) == " ") {
      c = c.substring(1)
    }

    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length)
    }
  }

  return ""
}

export const checkCookie = (name) => {
  let cookie = getCookie(name)

  return cookie != ""
}
