<template>
  <nav class="nav">
    <Logo class="nav--logo" />
    <div class="nav-user-row">
      <div class="nav-user-info-wrapper">
        <UserIcon class="user-icon" />
        <div class="user-info">
          <span class="user-info--name">привіт, Федір</span>
          <span class="user-info--role">адмін</span>
        </div>
      </div>
      <SettingsIcon class="settings-icon" />
    </div>
    <div class="nav-sections">
      <div
        v-for="section in navMenu"
        :key="section.id"
        class="nav-sections--item"
      >
        <component
          :is="section.url ? 'router-link' : 'span'"
          :disabled="!section.url"
          :to="section.url || ''"
          :class="[
            'nav-link',
            { 'router-link-active': $route.path.includes(getPath(section.url)) }
          ]"
        >
          <div class="nav-section-row" @click="onSelectSection(section.id)">
            <div class="section-label-wrapper">
              <icon-folder-active
                v-if="
                  section.url === $route.path ||
                  getPath(section.url) === $route.path
                "
                class="nav-sections--item_icon"
              />
              <icon-folder v-else class="nav-sections--item_icon" />
              <span class="nav-sections--item-label">{{ section.label }}</span>
              <WarningIcon v-if="section?.error" />
            </div>
            <ArrowRightIcon
              v-if="section.subItems.length > 0"
              class="icon-down"
              :class="[section.id === selectedSectionId && 'icon-down-active']"
            />
          </div>

          <div
            v-if="
              section.subItems.length > 0 && section.id === selectedSectionId
            "
            class="sections"
          >
            <AppNavLink
              v-for="link in section.subItems"
              :key="link.id"
              :href="link.url"
              :active="link.url === $route.path"
              :error="link?.error"
            >
              <template #icon>
                <icon-folder-active
                  v-if="
                    link.url === $route.path ||
                    getPath(link.url) === $route.path
                  "
                  class="nav-sections--item_icon"
                />
                <icon-folder v-else class="nav-sections--item_icon" />
              </template>
              <template #label>{{ link.label }}</template>
            </AppNavLink>
          </div>
        </component>

        <!-- <router-link
          :disabled="!section.url"
          :to="section.url || ''"
          class="nav-link"
        > -->

        <!-- </router-link> -->
      </div>
    </div>
  </nav>
</template>

<script>
import { mapGetters } from "vuex"

import AppNavLink from "./AppNavLink.vue"
import ArrowRightIcon from "./svg/ArrowRightIcon.vue"
import Logo from "./svg/Logo.vue"
import SettingsIcon from "./svg/SettingsIcon.vue"
import UserIcon from "./svg/UserIcon.vue"
import WarningIcon from "./svg/WarningIcon.vue"

import iconFolder from "@/components/svg/Folder.vue"
import iconFolderActive from "@/components/svg/FolderActive.vue"
import { ROUTE } from "@/router/routeNames"
import { namespace as activeComponentNamespace } from "@/store/modules/activeComponents"

export default {
  components: {
    AppNavLink,
    SettingsIcon,
    UserIcon,
    ArrowRightIcon,
    Logo,
    WarningIcon,
    iconFolder,
    iconFolderActive
  },

  mounted() {
    this.checkErrors()
  },

  data: () => ({
    selectedSectionId: 0,
    navMenu: [
      {
        label: "Журнал",
        id: 1,
        subItems: [
          {
            url: ROUTE.PROMOTIONS.path,
            label: "Акції",
            id: 2,
            navGroup: 1
          },
          {
            url: ROUTE.BANNERS.path,
            label: "Банери",
            id: 3,
            navGroup: 1
          },
          {
            url: ROUTE.BRANDS.path,
            label: "Бренди",
            id: 4,
            navGroup: 1
          },
          {
            url: ROUTE.REVIEWS.path,
            label: "Відгуки",
            id: 5,
            navGroup: 1
          }
        ]
      },
      {
        label: "Каталог",
        id: 2,
        subItems: [
          {
            url: ROUTE.SECTIONS_AND_FORMS.path,
            label: "Розділи та форми",
            id: 1,
            navGroup: 2
          },
          {
            url: ROUTE.ACTIVE_COMPONENTS.path,
            label: "Активні компоненти",
            id: 2,
            navGroup: 2
          },
          {
            url: ROUTE.CATALOG_FILTER.path,
            label: "Фільтри",
            id: 3,
            navGroup: 2
          },
          {
            url: ROUTE.CATALOG_COLORS.path,
            label: "Кольори",
            id: 4,
            navGroup: 2
          },
          {
            url: ROUTE.CATALOG_EFFECTS.path,
            label: "Ефекти",
            id: 5,
            navGroup: 2
          }
        ]
      },
      {
        id: 3,
        label: "Інструменти",
        subItems: []
      },
      {
        id: 4,
        label: "Аналітика",
        subItems: []
      },
      {
        id: 5,
        label: "Залишки",
        subItems: [
          {
            url: `${ROUTE.LEAVINGS.path}?page=1`,
            label: "База залишків",
            id: 1,
            navGroup: 2
          },
          {
            url: `${ROUTE.DOCUMENTS.path}?page=1`,
            label: "Документи",
            id: 2,
            navGroup: 2
          }
        ]
      },
      {
        id: 6,
        label: "Замовлення",
        url: `${ROUTE.ORDERS.path}?page=1`,
        subItems: []
      },
      {
        id: 7,
        label: "Торгові точки",
        url: `${ROUTE.TRADING_POINTS.path}?page=1`,
        subItems: []
      }
    ]
  }),

  computed: {
    ...mapGetters({
      activeComponentsError: `${activeComponentNamespace}/error`
    })
  },

  methods: {
    onSelectSection(id) {
      if (id === this.selectedSectionId) {
        this.selectedSectionId = 0
      } else {
        this.selectedSectionId = id
      }
    },

    checkErrors() {
      if (this.activeComponentsError) {
        let navGroup = this.findNavGroup(2)
        let groupSubItem = this.findGroupSubItem(2)

        navGroup.error = true
        groupSubItem.error = true
      }
    },

    findNavGroup(groupId) {
      return this.navMenu.find((item) => item.id === groupId)
    },

    findGroupSubItem(itemId) {
      let item = null

      this.navMenu.forEach((section) => {
        section.subItems.forEach((subItem) => {
          if (subItem.id === itemId) {
            item = subItem
          }
        })
      })
      return item
    },

    getPath(url) {
      var parser = document.createElement("a")

      parser.href = url
      return parser.pathname
    }
  }
}
</script>

<style lang="scss" scoped>
.nav::-webkit-scrollbar {
  display: none;
}

.nav {
  display: flex;
  flex-direction: column;
  background-color: $nav-color;
  min-width: 288px;
  max-width: 288px;
  padding-top: 24px;
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  height: 100%;

  &--logo {
    width: 102px;
    min-height: 24px;
    height: 24px;
    margin: 0 auto 24px auto;
  }

  &-user-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 11px 16px 11px 24px;
    background-color: $user-nav-color;
  }

  &-user-info-wrapper {
    display: flex;
    align-items: center;

    .user-icon {
      width: 24px;
      height: 24px;
      margin-right: 20px;
    }

    .user-info {
      display: flex;
      flex-direction: column;
      gap: 4px;

      &--name {
        line-height: 16px;
      }

      &--role {
        font-size: 14px;
      }
    }
  }

  .settings-icon {
    cursor: pointer;
  }

  &-section-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 16px 16px 24px;
  }

  &-sections {
    display: flex;
    flex-direction: column;

    &--item {
      display: flex;
      flex-direction: column;
      cursor: pointer;

      &:hover {
        background-color: #fff;
      }

      &-label {
        font-size: 14px;
        line-height: 16px;
        margin-right: 12px;
      }

      .section-links {
        display: flex;
        flex-direction: column;
        border-top: 1px solid $user-nav-color;
        border-bottom: 1px solid $user-nav-color;
      }
    }
  }

  &-link {
    text-decoration: none;
    outline: none;
    color: #000;

    &:active {
      color: #000;
    }

    &.router-link-active {
      border-top: 1px solid #99cccd;
      border-bottom: 1px solid #99cccd;
      background-color: #fff;
    }
  }
}

.section-label-wrapper {
  display: flex;
  align-items: center;
  gap: 20px;
}

.icon-down {
  width: 16px;
  height: 16px;
  transform: rotate(90deg);
  transition: transform 0.2s;
}

.icon-down-active {
  transform: rotate(-90deg);
}
</style>
