import axios from "axios"

import * as COOKIE_KEYS from "../constants/cookieKeys"
import { ERRORS } from "../constants/strings"
import * as cookieActions from "../helpers/cookies"

const STATUS_CODE = {
  UNAUTHORIZED: 401,
  NOT_FOUND: 404
}

const axiosClient = axios.create({
  baseURL: process.env.VUE_APP_API,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json"
  }
})

axiosClient.interceptors.response.use(
  (response) => {
    console.log("RESPONSE", response)
    return response
  },
  (error) => {
    let res = error.response

    console.log("ERROR", res)

    switch (res.status) {
      case STATUS_CODE.UNAUTHORIZED:
        throw new Error({
          message: ERRORS.UNAUTHORIZED,
          code: STATUS_CODE.UNAUTHORIZED
        })
      case STATUS_CODE.NOT_FOUND:
        throw new Error({
          message: ERRORS.NOT_FOUND,
          code: STATUS_CODE.NOT_FOUND
        })
      default:
        return Promise.reject(error)
    }
  }
)

export const sendRequest = () => axiosClient

export const sendSecureRequest = () => {
  const token = cookieActions.getCookie(COOKIE_KEYS.TOKEN)

  if (token) {
    axiosClient.defaults.headers.common["Authorization"] = `Bearer ${token}`
    return axiosClient
  } else {
    throw Error("No Token")
  }
}
