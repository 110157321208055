import { mutationTypes } from "./constants"

import api from "@/api"

const { orders, ordersID } = api.orders

export default {
  async getOrders({ commit }, params) {
    const { data } = await orders(params)

    commit(mutationTypes.SET_ORDERS, data)
  },

  async getOrdersId({ commit }, ID) {
    const response = await ordersID(ID)
    return response.data?.data
  }
}
