<template>
  <div id="app">
    <Component :is="layout">
      <RouterView v-slot="{ Component: cmpnt, route }">
        <Transition :name="route.meta.transition || 'page'" mode="out-in">
          <Component :is="cmpnt" />
        </Transition>
      </RouterView>
    </Component>
  </div>
</template>

<script>
const default_layout = "default"

export default {
  name: "App",
  computed: {
    layout() {
      return (this.$route.meta.layout || default_layout) + "-layout"
    }
  }
}
</script>

<style lang="scss">
@font-face {
  font-family: "Open Sans";
  font-weight: 300;
  src: local("Open Sans Light"), local("OpenSansLight"),
    url(./assets/fonts/OpenSans-Light.eot?#iefix) format("embedded-opentype"),
    url(./assets/fonts/OpenSans-Light.woff) format("woff"),
    url(./assets/fonts/OpenSans-Light.ttf) format("truetype");
}
@font-face {
  font-family: "Open Sans";
  font-weight: 400;
  src: local("Open Sans Regular"), local("OpenSansRegular"),
    url(./assets/fonts/OpenSans-Regular.eot?#iefix) format("embedded-opentype"),
    url(./assets/fonts/OpenSans-Regular.woff) format("woff"),
    url(./assets/fonts/OpenSans-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Open Sans";
  font-weight: 500;
  src: local("OpenSans"),
    url(./assets/fonts/OpenSans-Medium.ttf) format("truetype");
}

@font-face {
  font-family: "Open Sans";
  font-weight: 600;
  src: local("Open Sans SemiBold"), local("OpenSansSemiBold"),
    url(./assets/fonts/OpenSans-SemiBold.eot?#iefix) format("embedded-opentype"),
    url(./assets/fonts/OpenSans-SemiBold.woff) format("woff"),
    url(./assets/fonts/OpenSans-SemiBold.ttf) format("truetype");
}

@font-face {
  font-family: "Open Sans";
  font-weight: 700;
  src: local("Open Sans Bold"), local("OpenSansBold"),
    url(./assets/fonts/OpenSans-Bold.eot?#iefix) format("embedded-opentype"),
    url(./assets/fonts/OpenSans-Bold.woff) format("woff"),
    url(./assets/fonts/OpenSans-Bold.ttf) format("truetype");
}

#app {
  width: 100vw;
  height: 100%;
  min-height: 100%;

  overflow-x: hidden;
  overflow-y: hidden;

  font-family: "Open Sans", sans-serif;
  color: $black;
}
</style>
