export const ORDER_STATUSES = {
  PROCESSING: "обробляється",
  PROCESSING_PROBLEMATIC: "обробляється",
  GOING_TO: "збирається",
  COLLECTED: "зібрано",
  TRANSFERRED_NP: "передано НП",
  WAITING_AT_THE_POINT: "очікує в точці видачі",
  RECEIVED: "отримано",
  CANCELLED: "скасовано",
  RETURN: "повернення",
  PROBLEMATIC: "проблемне",
  IN_QUEUE: "в черзі",
  WAITING_DOWNLOAD: "очікує відвантаження",
  REFUSAL: "відмова",
  "": "preorder"
}

export const PAYMENT_STATUSES = {
  SUCCESSFUL: "успішно",
  SUCCESSFUL_HOLD: "успішно (hold)",
  PARTIAL_CANCELLATION: "часткове скасування",
  PAYMENT_ERROR: "помилка оплати",
  RETURNED: "повернуто",
  AWAITING_RETURN: "очікує на повернення",
  AWAITING_CONFIRMATION: "очікує на підтвердження адміном",
  REJECTED: "відхилено"
}
