<script>
import { mapGetters, mapActions, mapMutations } from "vuex"

import AppTable from "@/components/AppTable.vue"
import PageTitle from "@/components/PageTitle.vue"
import SearchInput from "@/components/SearchInput.vue"
import OrderItem from "@/pages/Orders/components/OrderItem.vue"
import CountElements from "@/components/pagination/countElements.vue"
import PaginationLaravel from "@/components/pagination/paginationLaravel.vue"
import VDropDownSlotsFixed from "@/components/ui/v-drop-down-slots-fixed"
import { PREV_SEARCH_ORDERS } from "@/constants/localStorageKeys.js"
import { debounce } from "@/helpers/strings"
import LeftoversFolderMixin from "@/mixins/leftoversFolder.mixin"
import { namespace } from "@/store/modules/orders"
import { mutationTypes } from "@/store/modules/orders/constants"
import { ROUTE } from "@/router/routeNames"
// import orderStatus from "./components/orderStatus.vue"
// import { ORDER_STATUSES, PAYMENT_STATUSES } from "./constants"
import { ORDER_STATUSES } from "./constants"
import LoadingSpinner from "@/components/LoadingSpinner.vue"

export default {
  name: "Orders",

  components: {
    PageTitle,
    SearchInput,
    AppTable,
    CountElements,
    PaginationLaravel,
    VDropDownSlotsFixed,
    OrderItem,
    LoadingSpinner
    // orderStatus
  },

  mixins: [LeftoversFolderMixin],

  data: () => ({
    search: "",
    searchDropDown: "",
    isActiveShopFiltering: true,
    selectedShop: {},
    disableSearchRequest: true,
    loading: true,
    page: 1,
    routeKey: "ORDERS",
    sort: Object.keys(ORDER_STATUSES)[0]
  }),

  async mounted() {
    const { query } = this.$route
    this.search = query.search || ""
    this.page = query.page || 1
    await this.getOrdersList()
  },

  computed: {
    ...mapGetters({
      orders: `${namespace}/orders`,
      ordersList: `${namespace}/ordersList`
    }),

    prevSearchList() {
      return PREV_SEARCH_ORDERS
    },

    sortOption() {
      return ORDER_STATUSES
    },

    filterShops() {
      return this.isActiveShopFiltering
        ? this.shops.filter((shop) =>
            shop.address
              .toLowerCase()
              .includes(this.searchDropDown.toLowerCase())
          )
        : this.shops
    }
  },

  watch: {
    search(phrase) {
      if (!this.disableSearchRequest) {
        debounce(() => {
          this.setSearchResult(phrase, "getOrdersList")
        }, 500)
      }
    }
  },

  methods: {
    ...mapActions({
      getOrders: `${namespace}/getOrders`
    }),

    ...mapMutations(namespace, {
      changeDetailsStatus: mutationTypes.CHANGE_DETAILS_STATUS
    }),

    async getOrdersList(page) {
      this.loading = true

      const params = {
        s: this.search || undefined,
        sort: this.sort || undefined,
        page: page || this.page
      }
      await this.getOrders(params)
      this.loading = false

      if (this.disableSearchRequest) this.disableSearchRequest = false
    },

    formatPhoneNumber(phoneNumber) {
      const cleaned = ("" + phoneNumber).replace(/\D/g, "")

      const match = cleaned.match(/^(\d{2})(\d{3})(\d{3})(\d{2})(\d{2})$/)

      if (match) {
        return `+${match[1]} ${match[2]} ${match[3]} ${match[4]} ${match[5]}`
      }

      return phoneNumber
    },

    onSort(key) {
      if (key !== this.sort) {
        this.sort = key
      }

      this.getOrdersList(1)
    },

    openPageOfDetail(orderId) {
      return this.$router.push({
        name: ROUTE.ORDERS_INFO.name,
        params: { orderId }
      })
    }
  }
}
</script>

<template>
  <div class="orders">
    <div class="orders-header">
      <PageTitle title="Замовлення" />
      <SearchInput
        v-model="search"
        :list-searches="listSearches()"
        :name-storage="prevSearchList"
        @selectPrevItem="search = $event"
      />
    </div>
    <div v-if="loading" class="loading-status">
      <LoadingSpinner />
    </div>
    <div v-else-if="!loading && ordersList && ordersList.length">
      <AppTable
        :list="ordersList"
        :disable-row-padding="true"
        list-id-key="id"
        :is-disabled-editing-sub-elements="true"
        @rowClicked="changeDetailsStatus($event)"
      >
        <template #header>
          <div class="header">
            <div>дата</div>
            <div>номер</div>
            <div>клієнт</div>
            <div>замовлення</div>
            <div>доставка</div>
            <div>оплата</div>
            <v-drop-down-slots-fixed class="table-grid--sort">
              <template v-slot:toggler>
                <span class="table-grid--sort_toggle">статус</span>
              </template>
              <template v-slot:content>
                <ul class="table-grid--sort_content">
                  <li
                    v-for="(value, key) in sortOption"
                    :key="key"
                    :class="{ active: key === sort }"
                    @click="onSort(key)"
                  >
                    {{ value }}
                  </li>
                </ul>
              </template>
            </v-drop-down-slots-fixed>
          </div>
        </template>
        <template #body="item">
          <OrderItem :item="item" @order:detail="openPageOfDetail" />
        </template>

        <template #endElement>
          <span></span>
        </template>
      </AppTable>

      <div class="orders-footer">
        <CountElements
          v-if="orders"
          :current="orders.to || NaN"
          :total="orders.total || NaN"
        />
        <PaginationLaravel
          v-if="orders"
          :info="orders"
          @changePage="changePage($event, 'getOrdersList')"
        />
        <span></span>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "./assets/index.scss";
</style>
