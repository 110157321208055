<template>
  <button class="v-button" :disabled="disabled" @click="onPressHandler">
    <span class="v-button--text">{{ label }}</span>
  </button>
</template>

<script>
export default {
  name: "v-btn",
  props: {
    label: {
      type: String
    },

    disabled: {
      type: Boolean,
      default: false
    }
  },

  emits: ["click"],

  methods: {
    onPressHandler() {
      if (this.disabled) return

      this.$emit("click")
    }
  }
}
</script>

<style lang="scss" scoped>
.v-button {
  width: fit-content;
  background-color: $black;
  padding: 12px 30px;
  border-radius: 33px;
  border: none;
  outline: none;

  &:disabled {
    background-color: $grey5;
  }

  &:hover {
    &:not(:disabled) {
      background-color: rgba($color: $black, $alpha: 0.7);
    }
  }
  &--text {
    color: $white;
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
  }
}
</style>
