<template>
  <div class="default-container">
    <app-nav-menu />
    <div class="default-page-wrapper">
      <slot />
    </div>
  </div>
</template>

<script>
import AppNavMenu from "../components/AppNavMenu.vue"

export default {
  components: {
    AppNavMenu
  }
}
</script>

<style lang="scss" scoped>
.default-container {
  display: flex;
  height: 100%;
  width: 100%;
}

.default-page-wrapper {
  width: 100%;
  height: 100%;
  padding: 28px 40px;
  overflow: scroll;

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}
</style>
