<script setup>
import { PAYMENT_STATUSES } from "../constants"
import orderStatus from "./orderStatus.vue"
import DateFormat from "@/components/date/DateFormat.vue"

const emit = defineEmits(["order:detail"])

const props = defineProps({
  item: { type: Object, default: () => ({}) }
})

const openOrderDetails = (ID) => emit("order:detail", ID)
const paymentStatus = (status) => PAYMENT_STATUSES[status] || ""

const formatPhoneNumber = (phoneNumber) => {
  const cleaned = ("" + phoneNumber).replace(/\D/g, "")

  const match = cleaned.match(/^(\d{2})(\d{3})(\d{3})(\d{2})(\d{2})$/)

  if (match) {
    return `+${match[1]} ${match[2]} ${match[3]} ${match[4]} ${match[5]}`
  }

  return phoneNumber
}
</script>

<template>
  <div
    class="row cursor-pointer"
    @click.stop.prevent="openOrderDetails(item.id)"
  >
    <div>
      <DateFormat
        :date="item?.created_at"
        formatter="DD.MM.YY HH:mm"
        v-slot="{ formattedDate }"
      >
        {{ formattedDate }}
      </DateFormat>
    </div>
    <div>{{ item.id }}</div>
    <div>
      <div>{{ item.customer }}</div>
      <div>{{ formatPhoneNumber(item.phone) }}</div>
    </div>
    <div>
      <div>{{ item.amount }}₴</div>
      <div class="counts">{{ item.quantity }} товарів</div>
    </div>
    <div>
      <div>{{ item.delivery?.type }}</div>
      <div>
        {{ item.delivery?.price ? `${item.delivery?.price}₴` : "безкоштовно" }}
      </div>
    </div>
    <div>{{ paymentStatus(item.payment_status) }}</div>
    <div class="ml-auto">
      <span class="alert-status" v-if="false">1год 25 хв</span>
      <order-status :status="item.status" v-if="!!item.status" />
    </div>
  </div>
</template>
