<template>
  <div class="page-wrapper">
    <AppNavigationBackTitle
      navLinkLabel="Банери"
      :show-arrow="true"
      :additionalLabel="isEdit ? new String(this.$route.query.counter) : null"
      :status="navigationStatus"
    />
    <div class="banner-data" v-if="loading">
      <span class="banner-data--parent-name">{{
        isEdit ? banner?.parentName : this.$route.query.title
      }}</span>
      <div class="banner-data--calendar">
        <span>з</span>
        <VDatePicker
          v-model.range="date"
          :mask="`DD.MM.YY HH:mm`"
          mode="dateTime"
          is24hr
          :minDate="Date.now()"
          :maxDate="dayjs(Date.now()).add(4, 'week').toDate()"
          :locale="{ id: 'uk', masks: { weekdays: 'WW' } }"
          :disabled-dates="notAvailableDate"
        >
          <template #default="{ inputValue, togglePopover }">
            <div class="banner-data--calendar_wrapper">
              <span
                @click="() => !allowEditing && isTouchDate(togglePopover)"
                class="banner-data--calendar_field"
              >
                {{ cutText(inputValue.start) }}
              </span>
              <span>по</span>
              <span
                @click="() => !allowEditing && isTouchDate(togglePopover)"
                class="banner-data--calendar_field"
              >
                {{ cutText(inputValue.end) }}
              </span>
            </div>
          </template>
        </VDatePicker>
        <span v-if="isErroDate" class="error">некоректна дата показу</span>
      </div>
    </div>
    <div class="banner-name">
      <label class="banner-name--label">Назва</label>
      <v-input
        v-model="title"
        :disabled="allowEditing"
        placeholder="введіть назву"
        class="v-input--underline banner-name--input"
      />
    </div>
    <div class="banner-banners">
      <input-banner
        :disabled="allowEditing"
        :counts="countBanner"
        :params="bannerParams"
        :banner-type="bannerType"
        :banners="{
          desktop: { ...desktopBanner },
          mobile: { ...mobileBanner }
        }"
        @addBanner="addBanner"
        @removeBanner="removeBanner"
      />
    </div>
    <div class="banner-bottom">
      <v-drop-down-slots :disabled="allowEditing" class="banner-format">
        <template v-slot:toggler>
          <span class="banner-format--label">{{ formatText }}</span>
        </template>
        <template v-slot:content="{ events: { onToggle } }">
          <banners-format-switcher
            :type-banner="maxCountFormat"
            class="banner-switcher"
            @setFormat="setFormat"
            @click="onToggle"
          />
        </template>
      </v-drop-down-slots>

      <div class="banner-links">
        <div
          v-for="link of convertsNumberToArray(countBanner)"
          :key="`link_${link}`"
          class="banner-links--item"
        >
          <BannerLink
            :idx="link"
            :disabled="allowEditing"
            :link="links[link]"
            @inputLink="inputLink($event, link)"
          />
          <div class="d-flex">
            <span style="margin-left: 91px"></span>
            <div class="banner-links--actions">
              <action-row
                v-if="links[link]?.promotion"
                :disabled="allowEditing"
                :actionData="links[link].promotion"
                @remove="removePromotion(link)"
              />
              <v-drop-down-slots
                v-else
                :disabled="allowEditing"
                class="banner-format"
              >
                <template v-slot:toggler>
                  <span class="add-action-text">приєднати акцію</span>
                </template>
                <template v-slot:content>
                  <PromotionSelector
                    :data="promotions"
                    class="banner-promotion"
                    @onSelectPromotion="onSelectPromotion($event, link)"
                  />
                </template>
              </v-drop-down-slots>
            </div>
          </div>
        </div>
      </div>
    </div>
    <v-drop-down-slots class="banner-history" v-if="isEdit && history.length">
      <template v-slot:toggler>
        <span class="banner-format--label">Історія змін</span>
      </template>
      <template v-slot:content>
        <ChangesHistory :history="history" class="banner-history--table" />
      </template>
    </v-drop-down-slots>
    <div class="banner-buttons">
      <v-btn
        v-if="!isEdit"
        :disabled="disabledCreate"
        :label="'ЗБЕРЕГТИ'"
        @click="onCreateBanner()"
      />
      <v-btn
        v-else-if="allowEditing && isEdit"
        :label="'ЗМІНИТИ'"
        @click="onAllowEditing()"
      />
      <v-btn
        v-else-if="!allowEditing && isEdit"
        :disabled="disabledUpdate"
        :label="'ЗБЕРЕГТИ'"
        @click="onUpdateBanner()"
      />
      <v-btn label="ЗАКРИТИ" @click="onClose()" />
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs"
import customParseFormat from "dayjs/plugin/customParseFormat"
import { mapActions, mapGetters } from "vuex"

import ActionRow from "./components/ActionRow.vue"
import BannerLink from "./components/BannerLinks.vue"
import BannersFormatSwitcher from "./components/BannersFormatSwitcher.vue"
import PromotionSelector from "./components/PromotionSelector.vue"

import AppNavigationBackTitle from "@/components/AppNavigationBackTitle.vue"
import ChangesHistory from "@/components/ChangesHistory.vue"
import InputBanner from "@/components/InputBanner/index.vue"
import {
  PLATFORM,
  BANNER_FORMAT,
  STATUS_BANNER,
  POSITION_BANNER,
  TYPE_BANNER
} from "@/constants/banners"
import { getDatesBetween, validateDateTime } from "@/helpers/date"
import { convertsNumberToArray } from "@/helpers/files"
import { ROUTE } from "@/router/routeNames"
import { namespace } from "@/store/modules/banners"

dayjs.extend(customParseFormat)

export default {
  name: "Banner",
  components: {
    AppNavigationBackTitle,
    InputBanner,
    BannersFormatSwitcher,
    BannerLink,
    PromotionSelector,
    ChangesHistory,
    ActionRow
  },

  data: () => ({
    title: "",
    date: {
      start: new Date(),
      end: new Date(Date.now() + 3600 * 1000 * 24)
    },

    countBanner: 1,
    desktopBanner: null,
    mobileBanner: null,
    links: [],
    history: [],
    loading: false,
    disabledBtn: true,
    allowEditing: false,
    notAvailableDate: [],
    banner: null,
    touchDate: false
  }),

  async mounted() {
    if (!this.allBanners.length) {
      await this.getBanners()
    }

    if (this.isEdit) {
      this.allowEditing = true
      this.banner = await this.getCurrentBanner(this.$route.params.bannerId)
      this.initData()
      this.loading = true
    }

    this.notAvailableDate = await this.availableDate({
      parentID: this.isEdit ? this.banner.groupId : this.$route.params.parentId,
      bannerID: this.banner?.id
    })
    await this.getPromotions()
    this.loading = true
  },

  unmounted() {
    this.notAvailableDate = []
  },

  computed: {
    ...mapGetters(namespace, ["allBanners"]),
    ...mapGetters("promotions", ["promotions"]),

    formatText() {
      return BANNER_FORMAT[this.countBanner - 1].title || "Формат"
    },

    isEdit() {
      return this.$route.meta.status === STATUS_BANNER.EDIT
    },

    navigationStatus() {
      return this.isEdit ? this.banner?.status : null
    },

    disabledCreate() {
      return !(
        this.desktopBanner &&
        this.mobileBanner &&
        this.disabledBtn &&
        this.title &&
        !this.isErroDate
      )
    },

    disabledUpdate() {
      const equalArrays = (a, b) => {
        if (a.length !== b.length) return []

        const arr3 = []

        for (let i = 0; i < a.length; i++) {
          if (a[i].link !== b[i].link) {
            arr3.push(a[i])
          }
        }

        return arr3
      }

      let validData = false

      if (this.loading) {
        validData = [
          this.title === this.banner.name,
          this.countBanner === +this.banner.typeCount,
          this.date.start === this.banner.dateStart,
          this.date.end === this.banner.dateEnd,
          this.desktopBanner?.url === this.banner.imageDesktop?.url,
          this.mobileBanner?.url === this.banner.imageMobile?.url,
          !equalArrays(this.links, this.banner.imageDesktop.links).length,
          !this.isErroDate
        ].some((el) => el === false)
      }

      return !(!this.disabledCreate && validData)
    },

    bannerParams() {
      return POSITION_BANNER[this.bannerType]
    },

    bannerType() {
      return this.$route.query.type
    },

    maxCountFormat() {
      return this.bannerType === TYPE_BANNER.HEADER ||
        this.bannerType === TYPE_BANNER.BIG_BANNER
        ? 3
        : 2
    },

    isErroDate() {
      return (
        this.date.end - this.date.start < 3600000 ||
        dayjs(this.date.end).valueOf() <= dayjs(this.date.start).valueOf() ||
        this.isValidDate ||
        this.isEndDateTimeValid
      )
    },

    isValidDate() {
      const arrDisabledDate = this.notAvailableDate
        .map((el) =>
          getDatesBetween(el.start, el.end, "DD.MM.YYYY").reduce(
            (sum, el) => [...sum, el.formatedDate],
            []
          )
        )
        .reduce((sum, el) => [...sum, ...el], [])
      const arrSelectDate = getDatesBetween(
        this.date.start,
        this.date.end,
        "DD.MM.YYYY"
      ).reduce((sum, el) => [...sum, el.formatedDate], [])
      const finish = arrSelectDate.some((el) => arrDisabledDate.includes(el))
      return this.touchDate ? finish : false
    },

    isEndDateTimeValid() {
      return !this.isEdit && validateDateTime(this.date.end)
    }
  },

  watch: {
    countBanner(newValue, oldValue) {
      // видялю зайві object, якщо countType зменшили
      const countItem = oldValue - newValue

      if (oldValue > newValue) {
        if (this.links.length > newValue) {
          this.links.splice(this.links.length - countItem, countItem)
        }
      }
    },

    "date.start"(newValue) {
      if (typeof newValue !== "number") {
        this.date.start = dayjs(newValue).valueOf()
      }
    },

    "date.end"(newValue) {
      if (typeof newValue !== "number") {
        this.date.end = dayjs(newValue).valueOf()
      }
    }
  },

  methods: {
    convertsNumberToArray,
    getDatesBetween,
    dayjs,

    ...mapActions(namespace, [
      "createBanner",
      "getCurrentBanner",
      "updateBanner",
      "availableDate",
      "getBanners"
    ]),

    ...mapActions("promotions", ["getPromotions"]),

    isTouchDate(fn) {
      this.touchDate = true
      fn()
    },

    initData() {
      const {
        history,
        name,
        dateStart,
        dateEnd,
        typeCount,
        imageDesktop,
        imageMobile
      } = this.banner

      this.title = name
      this.date.start = dateStart
      this.date.end = dateEnd
      this.countBanner = +typeCount
      this.desktopBanner = imageDesktop
      this.mobileBanner = imageMobile
      this.history = history
      this.links = [...imageMobile.links]
    },

    onClose() {
      this.$router.back()
    },

    cutText(text) {
      if (!text) return ""
      const mask = "DD.MM.YY HH:mm"
      const momentDate = dayjs(text, "DD.MM.YYYY HH:mm")
      return dayjs(momentDate).format(mask)
    },

    addBanner({ banner }) {
      if (banner.type === PLATFORM.DESKTOP) {
        this.desktopBanner = this.isEdit
          ? { ...banner, id: this.banner.imageDesktop?.id }
          : banner
      } else {
        this.mobileBanner = this.isEdit
          ? { ...banner, id: this.banner.imageMobile?.id }
          : banner
      }
    },

    removeBanner({ platform }) {
      if (platform === PLATFORM.DESKTOP) {
        this.desktopBanner = null
      } else {
        this.mobileBanner = null
      }
    },

    setFormat(format) {
      this.countBanner = format
    },

    onSelectPromotion(promotion, idx) {
      if (this.links[idx]) {
        this.links[idx].promotion = promotion
        this.links[idx].link = promotion.link
      } else {
        this.links.push({
          id: idx,
          link: promotion.link,
          promotion: promotion
        })
      }
    },

    removePromotion(index) {
      this.links[index] = {
        id: this.links[index].id,
        link: null,
        promotion: null
      }
    },

    inputLink(value, idx) {
      if (this.links[idx]) {
        this.links[idx].link = value
      } else {
        this.links.push({ id: idx, link: value })
      }
    },

    generateImageData(banner) {
      const data = {
        name: banner.name || "image",
        imgData: banner.imgData || banner.url,
        links: convertsNumberToArray(this.countBanner).map((idx) => ({
          ...(this.links[idx]?.id && { id: this.links[idx].id }),
          link: this.links[idx] ? this.links[idx].link : null,
          promotion: this.links[idx]
            ? this.links[idx]?.promotion?.id || null
            : null
        }))
      }

      if (banner.id) {
        data.id = banner.id
      }

      if (this.isEdit && !banner?.imgData) {
        data.url = banner.url
        delete data.imgData
      }

      return data
    },

    onAllowEditing() {
      this.allowEditing = false
    },

    formatDateLocal(date, format = "YYYY-MM-DD HH:mm:ss") {
      return dayjs(date).format(format)
    },

    async onCreateBanner() {
      this.touchDate = true
      const newData = {
        groupId: +this.$route.params.parentId,
        name: this.title,
        dateStart: this.formatDateLocal(this.date.start),
        dateEnd: this.formatDateLocal(this.date.end),
        typeCount: this.countBanner,
        imageDesktop: this.generateImageData(this.desktopBanner),
        imageMobile: this.generateImageData(this.mobileBanner)
      }

      if (!this.disabledCreate) {
        try {
          this.disabledBtn = false
          await this.createBanner(newData)
        } catch (e) {
          console.log("create banner error", e)
        }
      }
    },

    async onUpdateBanner() {
      this.touchDate = true
      const newData = {
        id: this.banner.id,
        groupId: this.banner.groupId,
        name: this.title,
        typeCount: this.countBanner,
        dateStart: this.formatDateLocal(this.date.start),
        dateEnd: this.formatDateLocal(this.date.end),
        counter: this.$route.query.counter,
        status: "Завершено",
        imageDesktop: this.generateImageData(this.desktopBanner),
        imageMobile: this.generateImageData(this.mobileBanner)
      }

      if (!this.disabledUpdate) {
        try {
          this.disabledBtn = false
          await this.updateBanner(newData)
          this.disabledBtn = true
          this.allowEditing = true
        } catch {
          this.disabledBtn = true
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/css/pages/banner.scss";
</style>
