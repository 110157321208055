<script setup>
import { useDateFormat } from "@vueuse/core"
import { defineComponent, reactive } from "vue"

const props = defineProps({
  date: { type: String, default: "" },
  formatter: { type: String, default: "DD.MM.YY HH:mm" }
})

const formattedDate = reactive(useDateFormat(() => props.date, props.formatter))
</script>

<template>
  <slot :formattedDate="date ? formattedDate : '-'"> </slot>
</template>
